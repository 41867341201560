import React, { lazy, Suspense } from "react";
import Page from "@jumbo/shared/Page";
import authRoutes from "./authRoutes";
import appsRoutes from "./appsRoutes";
import ProtectedRoute from "app/ProtectedRoute";
import { Navigate } from "react-router-dom";
import { Tenantname } from "app/services/auth-services";
import NotFoundPage from "app/pages/NotFoundPage";
import { CRMCustomLoader } from "app/pages/widgets/StylesComponents";



// landing pages
const LandingPage = lazy(() => import('app/pages/landing-page/LandingPage'));


// lazy routes
const Home = lazy(() => import('app/pages/home/Home'));
const ListTemplates = lazy(() => import('app/pages/useradmin-pages/Templates/ListTemplate'));
const InvoiceListTemplates = lazy(() => import('app/pages/useradmin-pages/InvoiceTemplates/ListInvoiceTemplate'));
const EmailLists = lazy(() => import('app/pages/home/email-lists/EmailLists'));
const ChatLists = lazy(() => import('app/pages/home/chat-lists/ChatLists'));
const CalenderList = lazy(() => import('app/pages/home/calender-lists/CalenderList'));
const ContactsListLeftCard = lazy(() => import('app/pages/contacts/ContactsListLeftCard'));
const OverviewContact = lazy(() => import('app/pages/contacts/overviewcontact/OverviewContact'));
const AccountOverview = lazy(() => import('app/pages/sales/sales/Dashboard/AccountOverview'));
const CallCenterLeftCardLists = lazy(() => import('app/pages/call-center/call-center-left-side-card/CallCenterLeftCardLists'));
const LeadsDashBoard = lazy(() => import('app/pages/leads/LeadsDashBoard'));
const QualifiedLeads = lazy(() => import('app/pages/leads/QualifiedLeads'));
const UnqualifiedLeads = lazy(() => import('app/pages/leads/UnqualifiedLeads'));
const LeadGeneration = lazy(() => import('app/pages/leads/LeadGeneration'));
const SalesDashboardRightCard = lazy(() => import('app/pages/sales/Dashboard/SalesDashboardRightCard'));
const SalesLeftCard = lazy(() => import('app/pages/sales/SalesLeftCard'));
// const ProductMaster = lazy(() => import('app/pages/sales/ProductMaster'));
const OpeningStock = lazy(() => import('app/pages/sales/OpeningStock'));
const TerritoryView = lazy(() => import('app/pages/sales/Territory-Mgmt/sales-target/TerritoryView'));
// const ProductCategories = lazy(() => import('app/pages/sales/ProductCategories'));
const ListSupplier = lazy(() => import('app/pages/sales/supplier/ListSupplier'));
const Discount = lazy(() => import('app/pages/sales/Discount'));
const StoreMaster = lazy(() => import('app/pages/sales/Store/createStore'));
const ProductInwardLists = lazy(() => import('app/pages/sales/ProductInwards/ProductInwardsList'));
const ManagePrice = lazy(() => import('app/pages/sales/ManagePrice'));
const MyOffer = lazy(() => import('app/pages/sales/Offer/MyOffer'));
const SalesTarget = lazy(() => import('app/pages/sales/Territory-Mgmt/sales-target/SalesTarget'));
const SalesPersonTargets = lazy(() => import('app/pages/sales/Territory-Mgmt/sales-person-target/SalesPersonTarget'));
const ExpenseList = lazy(() => import('app/pages/sales/Expense/ExpenseList'));


// Auth Pages
const login = lazy(() => import("app/pages/auth-pages/login"));
const ForgotPassword = lazy(() => import("app/pages/auth-pages/forgot-password/ForgotPassword"));
const ResetPassword = lazy(() => import("app/pages/auth-pages/forgot-password/ResetPassword"));

// Task Management Pages
const ListTaskManagement = lazy(() => import("app/pages/useradmin-pages/taskmanagement/ListTaskManagement"));
const UpdateTask = lazy(() => import("app/pages/useradmin-pages/taskmanagement/UpdateTask"));
const AssignTaskLists = lazy(() => import("app/pages/useradmin-pages/taskmanagement/AssignTaskLists"));
const TaskCalendarView = lazy(() => import("app/pages/useradmin-pages/taskmanagement/TaskCalendarView"));
const AssignTask = lazy(() => import("app/pages/taskmanagement/AssignTask"));
const TaskHierarchy = lazy(() => import("app/pages/taskmanagement/TaskHierarchy"));
const TaskReport = lazy(() => import("app/pages/taskmanagement/TaskReport"));
const UpdateTaskList = lazy(() => import("app/pages/taskmanagement/UpdateTaskList"));
const TaskManagementList = lazy(() => import("app/pages/Task Management/task-mgmt-list/TaskManagamentList"));
const TaskStatusKanben = lazy(() => import("app/pages/Task Management/task-status-kanben/TaskStatusKanben"));
const TaskCalender = lazy(() => import("app/pages/Task Management/task-calender/TaskCalender"));
const TaskGanttChart = lazy(() => import("app/pages/Task Management/task-gantt-chart/TaskGanttChart"));
const ProjectGanttChart = lazy(() => import("app/pages/taskmanagement/ProjectGanttChart"));

// Licence Pages
const ListLicence = lazy(() => import("app/pages/useradmin-pages/licence/AdditionalLicence/ListLicence"));
const CreateLicence = lazy(() => import("app/pages/useradmin-pages/licence/AdditionalLicence/CreateLicence"));
const ListStorage = lazy(() => import("app/pages/useradmin-pages/licence/Storage/ListStorage"));
const CreateStorage = lazy(() => import("app/pages/useradmin-pages/licence/Storage/CreateStorage"));
const ListAddonPurchase = lazy(() => import("app/pages/useradmin-pages/licence/AddonPurchase/ListAddonPurchase"));
const CreateAddonPurchase = lazy(() => import("app/pages/useradmin-pages/licence/AddonPurchase/CreateAddonPurchase"));
const CancelPlans = lazy(() => import("app/pages/useradmin-pages/licence/CancelPlan/CancelPlans"));
const ListRenewal = lazy(() => import("app/pages/useradmin-pages/licence/Renewal/ListRenewal"));

// Lookup Pages
const ListLookup = lazy(() => import("app/pages/useradmin-pages/lookup/ListLookup"));
const CreateLookup = lazy(() => import("app/pages/useradmin-pages/lookup/CreateLookup"));

// Ticket Pages
const ListTickets = lazy(() => import("app/pages/useradmin-pages/ticket/ListTickets"));
const CreateTickets = lazy(() => import("app/pages/useradmin-pages/ticket/CreateTickets"));
const ViewTicketResponse = lazy(() => import("app/pages/useradmin-pages/ticket/ViewTicketResponse"));
const DeveloperTicket = lazy(() => import("app/pages/useradmin-pages/ticket/Developeticket"));
const CreatedTicketList = lazy(() => import("app/pages/useradmin-pages/ticket/CreatedTicketList"));
const TicketsManagementGroup = lazy(() => import("app/pages/useradmin-pages/ticket/TicketManagement"));

// Settings Pages
const GeneralSettings = lazy(() => import("app/pages/useradmin-pages/settings/GeneralSettings"));
const RolesAndUsers = lazy(() => import("app/pages/useradmin-pages/settings/RolesAndUsers/RolesAndUsers"));
const RegistrationForm = lazy(() => import("app/pages/useradmin-pages/settings/RolesAndUsers/RegistrationForm"));
const TransactionApproval = lazy(() => import("app/pages/useradmin-pages/settings/TransactionApproval"));
const TransactionSeries = lazy(() => import("app/pages/useradmin-pages/settings/Preferences/TransactionSeries"));
const SystemPreference = lazy(() => import("app/pages/useradmin-pages/settings/Preferences/SystemPreference"));
const OrganizationStructure = lazy(() => import("app/pages/useradmin-pages/settings/Organization/OrganizationStructure"));
const DealFactorsetup = lazy(() => import("app/pages/useradmin-pages/settings/Deal/DealFactorsetup"));
const DealPredictsetup = lazy(() => import("app/pages/useradmin-pages/settings/Deal/DealPredictsetup"));
const DealWeightsetup = lazy(() => import("app/pages/useradmin-pages/settings/Deal/DealWeightsetup"));
const DealProbability = lazy(() => import("app/pages/useradmin-pages/settings/Deal/DealProbability"));
const LeadPrediction = lazy(() => import("app/pages/useradmin-pages/settings/LeadScoring/LeadPrediction"));
const LeadStatus = lazy(() => import("app/pages/useradmin-pages/settings/LeadScoring/LeadStatus"));
const SalesPersonTarget = lazy(() => import("app/pages/useradmin-pages/settings/SalesPersonTarget"));
const LeadScore = lazy(() => import("app/pages/useradmin-pages/settings/LeadScoring/LeadScore"));
const LeadScoringtypes = lazy(() => import("app/pages/useradmin-pages/settings/LeadScoring/LeadScoringtypes"));
const EditCompanyDetails = lazy(() => import("app/pages/useradmin-pages/settings/Company/EditCompanyDetails"));

// Home Pages
const AdminDashboard = lazy(() => import("app/pages/useradmin-pages/home/Admin-Dashboard"));

// Mail Pages
const AdminEmailLists = lazy(() => import("app/pages/useradmin-pages/mail/EmailLists"));

// Analytics Pages
const AnalyticsLeftSide = lazy(() => import("app/pages/analytics/AnalyticsLeftSide"));
const SalesByCustomer = lazy(() => import("app/pages/analytics/SalesByCustomer"));
const ActivityOverview = lazy(() => import("app/pages/analytics/ActivityOverview"));
const SalesGoals = lazy(() => import("app/pages/analytics/SalesGoals"));
const StageConversionByOwner = lazy(() => import("app/pages/analytics/StageConversionByOwner"));
const StageDurationAnalysis = lazy(() => import("app/pages/analytics/StageDurationAnalysis"));
const TimetoFirstActionbyOwner = lazy(() => import("app/pages/analytics/TimetoFirstActionbyOwner"));
const CallDuration = lazy(() => import("app/pages/analytics/CallDuration"));
const CallLengthVsTimeOfDay = lazy(() => import("app/pages/analytics/CallLengthVsTimeOfDay"));
const CRMDashBoard = lazy(() => import("app/pages/analytics/CRMDashBoard"));
const SalesKPIDashboard = lazy(() => import("app/pages/analytics/SalesKPIDashboard"));
const ChurnAnalysisDashboard = lazy(() => import("app/pages/analytics/ChurnAnalysisDashboard"));
const KeyPerformanceIndicator = lazy(() => import("app/pages/analytics/KeyPerformanceIndicator"));
const SalesDashboard = lazy(() => import("app/pages/analytics/SalesDashboard"));
const SalesOpportunitiesOverview = lazy(() => import("app/pages/analytics/SalesOpportunitiesOverview"));
const OpportunitiesIndividualReport = lazy(() => import("app/pages/analytics/OpportunitiesIndividualReport"));
const ActivityIndividualReport = lazy(() => import("app/pages/analytics/ActivityIndividualReport"));
const CustomerRententionDashboard = lazy(() => import("app/pages/analytics/CustomerRententionDashboard"));
const LeadPerformance = lazy(() => import("app/pages/analytics/LeadPerformance"));
const CallActivities = lazy(() => import("app/pages/analytics/CallActivities"));
const MonthlySalesDashboard = lazy(() => import("app/pages/analytics/MonthlySalesDashboard"));
const SalesProductPerformance = lazy(() => import("app/pages/analytics/SalesProductPerformance"));
const ExecutiveSalesPerformance = lazy(() => import("app/pages/analytics/ExecutiveSalesPerformance"));
const TotalSalesAndGrowth = lazy(() => import("../pages/analytics/TotalSalesAndGrowth"));
const SalesVolumeByChannel = lazy(() => import("app/pages/analytics/SalesVolumeByChannel"));
const MonthlySalesActivityReport = lazy(() => import("app/pages/analytics/MonthlySalesActivityReport"));
const MonthlySalesCallReport = lazy(() => import("app/pages/analytics/MonthlySalesCallReport"));
const MonthlyRetailSales = lazy(() => import("app/pages/analytics/MonthlyRetailSales"));
const SimpleMonthlySalesReport = lazy(() => import("app/pages/analytics/SimpleMonthlySalesReport"));
const LeadSources = lazy(() => import("app/pages/analytics/LeadSources"));

// Sales Pages
const Deals = lazy(() => import("app/pages/deals/Deals"));
const PipeLineDeals = lazy(() => import("app/pages/deals/PipeLineDeals"));
const SalesOrderRightCard = lazy(() => import("app/pages/sales/Order/SalesOrderRightCard"));
const ScheduleRightCard = lazy(() => import("app/pages/sales/Schedule/ScheduleRightCard"));
const MyShipmentRight = lazy(() => import("app/pages/sales/shipment/MyShipmentRight"));
const SalesMyPaymentRightCard = lazy(() => import("app/pages/sales/Payment/SalesMyPaymentRightCard"));
const EnquirydRightCard = lazy(() => import("app/pages/sales/Enquiry/EnquiryRightCard"));
const SalesInvoiceRightCard = lazy(() => import("app/pages/sales/Invoice/SalesInvoiceRightCard"));
const ShipmentCalender = lazy(() => import("app/pages/sales/ShipmentCalender"));
const FeedbackQstpage = lazy(() => import("app/pages/sales/sales/FeedbackQstpage"));
const Feedback = lazy(() => import("app/pages/sales/sales/feedback"));
const DashboardSales = lazy(() => import("app/pages/sales/sales/Dashboard"));
const ConversationSales = lazy(() => import("app/pages/sales/sales/Conversations"));
const AccountSales = lazy(() => import("app/pages/sales/sales/Accounts"));


// new lazy 

const CampaignDashboard = lazy(() => import("app/pages/campaign/CampaignDashboard"));
const EmailMainPage = lazy(() => import("app/pages/campaign/campaign-right-side-card/email/EmailMainPage"));
const StatisticsRightCardList = lazy(() => import("app/pages/campaign/campaign-right-side-card/StatisticsRightCard"));
const SLA = lazy(() => import("app/pages/customersupport/SLA"));
const SummaryRightSideCardList = lazy(() => import("app/pages/customersupport/customersupport-right-card/SummaryRightSideCardList"));
const TicketManagementTicket = lazy(() => import("app/pages/customersupport/TicketManagementTicket"));
const TicketStatus = lazy(() => import("app/pages/customersupport/TicketStatus"));
const SetupList = lazy(() => import("app/pages/useradmin-pages/setup/SetupList"));
const OverviewDealContact = lazy(() => import("app/pages/deals/overviewcontact/OverviewContact"));
const OverviewLead = lazy(() => import("app/pages/leads/overviewlead/OverviewLead"));
const LeadscoringSetup = lazy(() => import("app/pages/useradmin-pages/setup/LeadscoringSetup"));
const GeoLocation = lazy(() => import("app/pages/sales/GeoLocation/GeoLocation"));
const GoogleCalendar = lazy(() => import("app/pages/thirdPartyAPI/GoogleCalendarAPI/GoogleCalendar"));
const CalendarView = lazy(() => import("app/pages/thirdPartyAPI/GoogleCalendarAPI/CalendarView"));
const GMAILAPI = lazy(() => import("app/pages/thirdPartyAPI/GmailAPI/GMAILAPI"));
const ListTaskManagement1 = lazy(() => import("app/pages/taskmanagement/ListTaskManagement"));
const SalesPerformance = lazy(() => import("app/pages/sales/TerritoryManagement/SalesPerformance"));
const LinkedInAPI = lazy(() => import("app/pages/thirdPartyAPI/LinkedInAPI/LinkedInAPI"));
const FacebookAPI = lazy(() => import("app/pages/thirdPartyAPI/FacebookAPI/FacebookAPI"));
const LeadQuestions = lazy(() => import("app/pages/LeadQuestions/LeadQuestions"));
const LeadQuestionsPage = lazy(() => import("app/pages/leads/LeadQuestionsPage"));
const FormBuilder = lazy(() => import("app/pages/Addmoremodule/Formbuilder"));
const DashboardDeals = lazy(() => import("app/pages/deals/DashboardDeals"));
const SocialMediaCampaign = lazy(() => import("app/pages/campaign/campaign-right-side-card/email/SocialMediaCampaign"));
const InstagramAPI = lazy(() => import("app/pages/thirdPartyAPI/InstagramAPI/InstagramAPI"));
const SalesRepPerformanceReport = lazy(() => import("app/pages/Reports/SalesPerformance/SalesRepPerformanceReport"));
const ProAndSerPerformanceReport = lazy(() => import("app/pages/Reports/SalesPerformance/ProAndSerPerformanceReport"));
const CFList = lazy(() => import("app/pages/Addmoremodule/CFList"));
const AddAgent = lazy(() => import("app/pages/useradmin-pages/Agent/Addagent"));
const ChatandSupport = lazy(() => import("app/shared/widgets/AuthUserDropdown/Chat&Support"));
const SalesForecastReports = lazy(() => import("app/pages/Reports/SalesPerformance/SalesForecastReports"));
const SalesActivityReports = lazy(() => import("app/pages/Reports/SalesPerformance/SalesActivityReports"));
const LeadConversionreport = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/LeadConversionreport"));
const Opportunitypipelinereport = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/Opportunitypipelinereport"));
const SalesForecast = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/SalesForecast"));
const DealSize = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/DealSize"));
const SalesFunnelReport = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/SalesFunnelReport"));
const LeadSourceReports = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/LeadSourceReports"));
const SalesPipeLineReport = lazy(() => import("app/pages/Reports/SalesPerformance/SalesPipeLineReport"));
const SalesPipelineReport = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/SalesPipelineReport"));
const OpportunityAgingReport = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/OpportunityAgingReport"));
const ConversionRateReport = lazy(() => import("app/pages/Reports/SalesPerformance/LeadReports/ConversionRateReport"));
const CustomerInteractionHistoryReport = lazy(() => import("app/pages/Reports/SalesPerformance/CustomerReports/CustomerInteractionHistoryreport"));
const EmailCampaignPerformanceReport = lazy(() => import("app/pages/Reports/SalesPerformance/CustomerReports/EmailCampaignPerformanceReport"));
const SocialMediaEngagementReport = lazy(() => import("app/pages/Reports/SalesPerformance/CustomerReports/SocialMediaEngagementReport"));
const CustomerSatisfactionReport = lazy(() => import("app/pages/Reports/SalesPerformance/CustomerReports/CustomerSatisfactionReport"));
const WebsiteTrafficReport = lazy(() => import("app/pages/Reports/SalesPerformance/CustomerReports/WebsiteTrafficReport"));
const LeadGenerationReport = lazy(() => import("app/pages/Reports/SalesPerformance/MarketingReports/LeadGenerationReport"));
const ConversionMarketingRateReport = lazy(() => import("app/pages/Reports/SalesPerformance/MarketingReports/ConversionRateReports"));
const CustomerChurnReport = lazy(() => import("app/pages/Reports/CustomerRetention/CustomerChurnReport"));
const CustomerLoyaltyReport = lazy(() => import("app/pages/Reports/CustomerRetention/CustomerLoyaltyReport"));
const CustomerRetentionSatisfactionReport = lazy(() => import("app/pages/Reports/CustomerRetention/CustomerSatisfactionReport"));
const CustomerEngagementReport = lazy(() => import("app/pages/Reports/CustomerRetention/CustomerEngagementReport"));
const CustomerLifetimeValueReport = lazy(() => import("app/pages/Reports/CustomerRetention/CustomerLifetimeValueReport"));
const OutlookInbox = lazy(() => import("app/pages/thirdPartyAPI/Microsoftoutlook/OutlookInbox"));
const CustomerEngagementList = lazy(() => import("app/pages/CustomerEnagement/CustomerEngagementList"));
const UnAsignedLeads = lazy(() => import("app/pages/leads/UnAsignedLeads"));
const WeeklyReport = lazy(() => import("app/pages/Reports/NewReports/WeeklyReport"));
const MonthlySalesReport = lazy(() => import("app/pages/Reports/NewReports/MonthlySalesReport"));
const CusChurnReports = lazy(() => import("app/pages/Reports/NewReports/CusChurnReports"));
const CusAdditionReports = lazy(() => import("app/pages/Reports/NewReports/CusAdditionReports"));
const CusRetentionReports = lazy(() => import("app/pages/Reports/NewReports/CusRetentionReports"));
const SalesrepPErformanceReport = lazy(() => import("app/pages/Reports/NewReports/SalesrepPErformanceReport"));
const LeadandOpp = lazy(() => import("app/pages/Reports/NewReports/LeadandOpp"));
const SalesperReports = lazy(() => import("app/pages/Reports/NewReports/SalesperReports"));
const SalesFunReport = lazy(() => import("app/pages/Reports/SalesFunnelReports/SalesFunnelReport"));
const PipelineDevReports = lazy(() => import("app/pages/Reports/SalesFunnelReports/PipelineDevReports"));
const StagedisCRMreports = lazy(() => import("app/pages/Reports/SalesFunnelReports/StagedisCRMreports"));
const DailySalesReport = lazy(() => import("app/pages/Reports/NewReports/DailySalesReport"));
const MainPage = lazy(() => import("app/pages/MLAnalytics/pages/MainPage"));
const Feedbacktemplate = lazy(() => import("app/pages/sales/sales/feedbackTemplate.js/Feedbacktemplate"));
const SatisfactionSurvey = lazy(() => import("app/pages/sales/sales/feedbackReports/SatisfactionSurvey"));
const SummaryReport = lazy(() => import("app/pages/sales/sales/feedbackReports/summaryReport"));
const MainChat = lazy(() => import("app/pages/chat/MainChat"));
const Hierarchy = lazy(() => import("app/pages/sales/Territory-Mgmt/hirarchy/Hirarchy"));
const SalesAchievements = lazy(() => import("app/pages/Reports/SalesPerformance/SalesAchievements"));
const ProjectManagementLists = lazy(() => import("app/pages/Project Management/project-mgmt-list/ProjectManagementLists"));
const ManagementLists = lazy(() => import("app/pages/Project Management/mile-stone-list/MilestoneLists"));
const DeliverablesLists = lazy(() => import("app/pages/Project Management/deliverable-list/DeliverablesLists"));
const ResourceLists = lazy(() => import("app/pages/Project Management/Resource-list/ResourceLists"));
const Resourcesummary = lazy(() => import("app/pages/Project Management/Project-Reports/Resourcesummary"));
const ShipmentDeliveryList = lazy(() => import("app/pages/sales/ShipmentDelivery/ShipmentDeliveryList"));
const Product_Catagory = lazy(() => import("app/pages/sales/ProductCategouie/ProductCategory/ProductCategoryList"));
const Product_Group = lazy(() => import("app/pages/sales/ProductCategouie/ProductGroup/ProductGroup"));
const Product_Item = lazy(() => import("app/pages/sales/ProductCategouie/ProductItem/ProductItem"));
const ListTax = lazy(() => import("app/pages/useradmin-pages/TaxSetup/ListTax"));
const FactoryLists = lazy(() => import("app/pages/useradmin-pages/FactorySetup/FactoryLists"));
const ListWorkflowRule = lazy(() => import("app/pages/Workflow/ListWorkflowRule"));
const CustormFormList = lazy(() => import("app/pages/Addmoremodule/CustormFormList"));
const NotificationHome = lazy(() => import("app/pages/NotificationMenu/NotificationHome"));
const NotificationSales = lazy(() => import("app/pages/sales/sales/Notification"));
const TaskLists = lazy(() => import("app/pages/Project Management/Task-list/TaskLists"));
const ProjectView = lazy(() => import("app/pages/Project Management/Project-Reports/ProjectView"));


// property management 
const PropertyLists = lazy(() => import('app/pages/Property Management/Property/PropertyLists'));
const UnitMasterList = lazy(() => import('app/pages/Property Management/UnitMaster/UnitMasterList'));
const BlockLists = lazy(() => import('app/pages/Property Management/PropertyBlock/BlockLists'));
const ApartmentList = lazy(() => import('app/pages/Property Management/Apartment/ApartmentList'));
const PlotMasterList = lazy(() => import('app/pages/Property Management/PlotMaster/PlotMasterList'));
const BookingLists = lazy(() => import('app/pages/Property Management/Booking/BookingLists'));
const CancellationLists = lazy(() => import('app/pages/Property Management/Cancellation/CancellationLists'));
const PropertyPortalsList = lazy(() => import('app/pages/Property Management/PropertyPortals/PropertyPortalsList'));




/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/

const routesForPublic = [
  // ...dashboardRoutes,
  // ...appsRoutes,
  ...authRoutes,

  // {
  //     path: "/",
  //     element: <ProtectedRoute component={Login} />,
  //     target: "_blank"
  // },


  {
    path: `landingpage`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LandingPage} />
      </Suspense>
    ),
  },


  {
    path: `home`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Home} />
      </Suspense>
    ),
  },
  {
    path: `template`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListTemplates} />
      </Suspense>
    ),
  },
  {
    path: `InvoiceTemplate`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={InvoiceListTemplates} />
      </Suspense>
    ),
  },
  {
    path: `app/mails`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={EmailLists} />
      </Suspense>
    ),
  },
  {
    path: `app/chats`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ChatLists} />
      </Suspense>
    ),
  },
  {
    path: `app/calender`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CalenderList} />
      </Suspense>
    ),
  },
  {
    path: `app/contacts`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ContactsListLeftCard} />
      </Suspense>
    ),
  },
  {
    path: `app/contacts/overview/:id`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OverviewContact} />
      </Suspense>
    ),
  },
  {
    path: `app/accounts/overview/:id`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AccountOverview} />
      </Suspense>
    ),
  },
  {
    path: `app/call-centre`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CallCenterLeftCardLists} />
      </Suspense>
    ),
  },
  {
    path: `leads`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadsDashBoard} />
      </Suspense>
    ),
  },
  {
    path: `leads/qualifiedLeads`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={QualifiedLeads} />
      </Suspense>
    ),
  },
  {
    path: `leads/unqualifiedLeads`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={UnqualifiedLeads} />
      </Suspense>
    ),
  },
  {
    path: `leads/leadgeneration`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadGeneration} />
      </Suspense>
    ),
  },



  {
    path: `sales/dashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesDashboardRightCard} />
      </Suspense>
    ),
  },
  {
    path: `sales`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesLeftCard} />
      </Suspense>
    ),
  },
  {
    path: `sales-opening-stock`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OpeningStock} />
      </Suspense>
    ),
  },
  {
    path: `sales-territory-list`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TerritoryView} />
      </Suspense>
    ),
  },
  {
    path: `sale-supplier-list`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListSupplier} />
      </Suspense>
    ),
  },
  {
    path: `sales-discount`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Discount} />
      </Suspense>
    ),
  },
  {
    path: `sales-createStore`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={StoreMaster} />
      </Suspense>
    ),
  },
  {
    path: `sales-product-inward`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ProductInwardLists} />
      </Suspense>
    ),
  },
  {
    path: `sales-Manage-price`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ManagePrice} />
      </Suspense>
    ),
  },
  {
    path: `sales-offer`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MyOffer} />
      </Suspense>
    ),
  },
  {
    path: `sales-target`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesTarget} />
      </Suspense>
    ),
  },


  {
    path: `sales-person-target`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesPersonTargets} />
      </Suspense>
    ),
  },
  {
    path: `sales-expense`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ExpenseList} />
      </Suspense>
    ),
  },
  {
    path: `sales-order-management`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesOrderRightCard} />
      </Suspense>
    ),
  },
  {
    path: `sales-performance`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesPerformance} />
      </Suspense>
    ),
  },
  {
    path: `sales-schedule`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ScheduleRightCard} />
      </Suspense>
    ),
  },
  {
    path: `sales-shipment`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MyShipmentRight} />
      </Suspense>
    ),
  },
  {
    path: `sales/ShipmentDelivery`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ShipmentDeliveryList} />
      </Suspense>
    ),
  },
  {
    path: `sales-invoice`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesInvoiceRightCard} />
      </Suspense>
    ),
  },
  {
    path: `sales-payments`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesMyPaymentRightCard} />
      </Suspense>
    ),
  },
  {
    path: `sales-customer-enquiry`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={EnquirydRightCard} />
      </Suspense>
    ),
  },
  // karthick
  {
    path: `sale-sales-feedbacktemplate`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Feedbacktemplate} />
      </Suspense>
    ),
  },
  {
    path: `settings/TaxSetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListTax} />
      </Suspense>
    ),
  },
  {
    path: `settings/FactorySetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={FactoryLists} />
      </Suspense>
    ),
  },
  {
    path: `SatisfactionSurveyReports`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SatisfactionSurvey} />
      </Suspense>
    ),
  },
  {
    path: `feedbacksummaryReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SummaryReport} />
      </Suspense>
    ),
  },
  // analysis new routes start
  {
    path: `analytics`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AnalyticsLeftSide} />
      </Suspense>
    ),
  },
  {
    path: `analytics/salesbycustomer`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesByCustomer} />
      </Suspense>
    ),
  },
  {
    path: `analytics/activityoverview`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ActivityOverview} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SalesGoals`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesGoals} />
      </Suspense>
    ),
  },
  {
    path: `analytics/stageconversionbyowner`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={StageConversionByOwner} />
      </Suspense>
    ),
  },
  {
    path: `analytics/stagedurationanalysis`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={StageDurationAnalysis} />
      </Suspense>
    ),
  },
  {
    path: `analytics/TimetoFirstActionbyOwner`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TimetoFirstActionbyOwner} />
      </Suspense>
    ),
  },
  {
    path: `analytics/LeadSources`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadSources} />
      </Suspense>
    ),
  },
  {
    path: `analytics/CallDuration`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CallDuration} />
      </Suspense>
    ),
  },
  {
    path: `analytics/CallLengthVsTimeOfDay`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CallLengthVsTimeOfDay} />
      </Suspense>
    ),
  },
  {
    path: `analytics/CRMDashBoard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CRMDashBoard} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SalesKPIDashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesKPIDashboard} />
      </Suspense>
    ),
  },
  {
    path: `analytics/ChurnAnalysisDashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ChurnAnalysisDashboard} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SalesDashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesDashboard} />
      </Suspense>
    ),
  },
  {
    path: `analytics/KeyPerformanceIndicator`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={KeyPerformanceIndicator} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SalesOpportunitiesOverview`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesOpportunitiesOverview} />
      </Suspense>
    ),
  },
  {
    path: `analytics/OpportunitiesIndividualReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OpportunitiesIndividualReport} />
      </Suspense>
    ),
  },
  {
    path: `analytics/ActivityIndividualReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ActivityIndividualReport} />
      </Suspense>
    ),
  },
  {
    path: `analytics/CustomerRententionDashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerRententionDashboard} />
      </Suspense>
    ),
  },
  {
    path: `analytics/LeadPerformance`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadPerformance} />
      </Suspense>
    ),
  },
  {
    path: `analytics/CallActivities`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CallActivities} />
      </Suspense>
    ),
  },
  {
    path: `analytics/MonthlySalesDashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MonthlySalesDashboard} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SalesProductPerformance`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesProductPerformance} />
      </Suspense>
    ),
  },
  {
    path: `analytics/ExecutiveSalesPerformance`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ExecutiveSalesPerformance} />
      </Suspense>
    ),
  },
  {
    path: `analytics/TotalSalesAndGrowth`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TotalSalesAndGrowth} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SalesVolumeByChannel`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesVolumeByChannel} />
      </Suspense>
    ),
  },
  {
    path: `analytics/MonthlySalesActivityReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MonthlySalesActivityReport} />
      </Suspense>
    ),
  },
  {
    path: `analytics/MonthlySalesCallReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MonthlySalesCallReport} />
      </Suspense>
    ),
  },
  {
    path: `analytics/SimpleMonthlySalesReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SimpleMonthlySalesReport} />
      </Suspense>
    ),
  },
  {
    path: `analytics/MonthlyRetailSales`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MonthlyRetailSales} />
      </Suspense>
    ),
  },


  // analysis new routes end

  {
    path: `analytics`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AnalyticsLeftSide} />
      </Suspense>
    ),
  },
  {
    path: `campaign-dashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CampaignDashboard} />
      </Suspense>
    ),
  },
  {
    path: `campaign-email`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={EmailMainPage} />
      </Suspense>
    ),
  },
  {
    path: `campaign-statistics`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={StatisticsRightCardList} />
      </Suspense>
    ),
  },
  {
    path: `deals-dashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DashboardDeals} />
      </Suspense>
    ),
  },
  {
    path: `deals`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Deals} />
      </Suspense>
    ),
  },
  {
    path: `deals-pipeline`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={PipeLineDeals} />
      </Suspense>
    ),
  },
  {
    path: `app/dealcontacts/overview/:id`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OverviewDealContact} />
      </Suspense>
    ),
  },
  {
    path: `app/Lead/overview/:id`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OverviewLead} />
      </Suspense>
    ),
  },
  {
    path: `customer-support-ticket-summary`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SummaryRightSideCardList} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/create-ticket`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TicketManagementTicket} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/ticket-livecharts`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MainChat} />
      </Suspense>
    ),
  },
  {
    path: `ticket/ticket-status`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TicketStatus} />
      </Suspense>
    ),
  },
  {
    path: `customer-support-sla`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SLA} />
      </Suspense>
    ),
  },




  // routes for user task management
  {
    path: `task/list-task1`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListTaskManagement1} />
      </Suspense>
    ),
  },

  {
    path: `task-management`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskManagementList} />
      </Suspense>
    ),
  },
  {
    path: `task-status`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskStatusKanben} />
      </Suspense>
    ),
  },
  {
    path: `task-gantt-chart`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskGanttChart} />
      </Suspense>
    ),
  },
  {
    path: `task-calendar`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskCalender} />
      </Suspense>
    ),
  },
  {
    path: `task/project`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ProjectManagementLists} />
      </Suspense>
    ),
  },
  {
    path: `task/ProjectView`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ProjectView} />
      </Suspense>
    ),
  },
  {
    path: `task/Milestone`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ManagementLists} />
      </Suspense>
    ),
  },
  {
    path: `task/Deliverables`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DeliverablesLists} />
      </Suspense>
    ),
  },
  {
    path: `task/Resource`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ResourceLists} />
      </Suspense>
    ),
  },
  {
    path: `task/projectTask`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskLists} />
      </Suspense>
    ),
  },
  {
    path: `task/Resourcesummary`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Resourcesummary} />
      </Suspense>
    ),
  },
  {
    path: `task/assign-task`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AssignTask} />
      </Suspense>
    ),
  },
  {
    path: `usertask/update-task`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={UpdateTaskList} />
      </Suspense>
    ),
  },
  {
    path: `usertask/gantt-chart`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ProjectGanttChart} />
      </Suspense>
    ),
  },
  {
    path: `task/report`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskReport} />
      </Suspense>
    ),
  },
  {
    path: `sales-sales-Dashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DashboardSales} />
      </Suspense>
    ),
  },
  {
    path: `sale-sales-Accounts`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AccountSales} />
      </Suspense>
    ),
  },
  {
    path: `sales-sales-Conversations`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ConversationSales} />
      </Suspense>
    ),
  },

   // property management
   {
    path: `PropertyLists`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={PropertyLists} />
      </Suspense>
    ),
  },
  {
    path: `UnitMaster`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={UnitMasterList} />
      </Suspense>
    ),
  },
  {
    path: `BlockLists`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={BlockLists} />
      </Suspense>
    ),
  },
  {
    path: `ApartmentLists`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ApartmentList} />
      </Suspense>
    ),
  },
  {
    path: `PlotLists`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={PlotMasterList} />
      </Suspense>
    ),
  },
  {
    path: `Booking`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={BookingLists} />
      </Suspense>
    ),
  },
  {
    path: `BookingCancellation`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CancellationLists} />
      </Suspense>
    ),
  },
  {
    path: `PropertyPortals`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={PropertyPortalsList} />
      </Suspense>
    ),
  },




  // routes for user admin
  {
    path: `user-admin-dashboard`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        {/* <ProtectedRoute component={AdminDashboard} /> */}
        <ProtectedRoute component={Home} />
      </Suspense>
    ),
  },


  // routes for task management
  {
    path: `task/list-task`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListTaskManagement} />
      </Suspense>
    ),
  },
  {
    path: `task/update-task`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={UpdateTask} />
      </Suspense>
    ),
  },
  {
    path: `task/assign-task`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AssignTaskLists} />
      </Suspense>
    ),
  },
  {
    path: `task/calendar-view`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskCalendarView} />
      </Suspense>
    ),
  },
  {
    path: `mail/email-lists`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AdminEmailLists} />
      </Suspense>
    ),
  },
  {
    path: `licence/list-licence`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListLicence} />
      </Suspense>
    ),
  },
  {
    path: `licence/create-licence`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CreateLicence} />
      </Suspense>
    ),
  },
  {
    path: `storage/list-storage`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListStorage} />
      </Suspense>
    ),
  },
  {
    path: `storage/create-storage`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CreateStorage} />
      </Suspense>
    ),
  },
  {
    path: `purchase/list-purchase`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListAddonPurchase} />
      </Suspense>
    ),
  },
  {
    path: `purchase/create-purchase`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CreateAddonPurchase} />
      </Suspense>
    ),
  },
  {
    path: `plan/cancel-plan`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CancelPlans} />
      </Suspense>
    ),
  },
  {
    path: `renewal/list-renewal`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListRenewal} />
      </Suspense>
    ),
  },
  {
    path: `lookup/list-lookup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListLookup} />
      </Suspense>
    ),
  },
  {
    path: `lookup/create-lookup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CreateLookup} />
      </Suspense>
    ),
  },
  {
    path: `ticket/Developecreate-ticket`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DeveloperTicket} />
      </Suspense>
    ),
  },
  {
    path: `ticket/ticket-Managementgroup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TicketsManagementGroup} />
      </Suspense>
    ),
  },
  {
    path: `ticket/ticket-Createdticketlist`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CreatedTicketList} />
      </Suspense>
    ),
  },
  {
    path: `ticket/list-ticket`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListTickets} />
      </Suspense>
    ),
  },
  {
    path: `ticket/create-ticket`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CreateTickets} />
      </Suspense>
    ),
  },
  {
    path: `ticket/ticket-response`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ViewTicketResponse} />
      </Suspense>
    ),
  },



  // settings submenu url
  {
    path: `settings/general`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={GeneralSettings} />
      </Suspense>
    ),
  },
  {
    path: `settings/edit-company-details`, // ponkumar changes
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={EditCompanyDetails} />
      </Suspense>
    ),
  },
  {
    path: `settings/roles&users`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={RolesAndUsers} />
      </Suspense>
    ),
  },
  {
    path: `settings/lead-scoring`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadScore} />
      </Suspense>
    ),
  },
  {
    path: `settings/lead-values`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadScoringtypes} />
      </Suspense>
    ),
  },
  {
    path: `settings/lead-prediction`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadPrediction} />
      </Suspense>
    ),
  },
  {
    path: `settings/lead-Scoringsetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadStatus} />
      </Suspense>
    ),
  },
  {
    path: `settings/deal-probability`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DealProbability} />
      </Suspense>
    ),
  },
  {
    path: `settings/deal-WeightSetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DealWeightsetup} />
      </Suspense>
    ),
  },
  {
    path: `settings/deal-FactorSetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DealFactorsetup} />
      </Suspense>
    ),
  },
  {
    path: `settings/deal-PredictSetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DealPredictsetup} />
      </Suspense>
    ),
  },
  {
    path: `settings/users/registration-form`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={RegistrationForm} />
      </Suspense>
    ),
  },
  {
    path: `settings/transaction-approval`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TransactionApproval} />
      </Suspense>
    ),
  },
  {
    path: `settings/preferences/transaction-series`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TransactionSeries} />
      </Suspense>
    ),
  },
  {
    path: `settings/preferences/system`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SystemPreference} />
      </Suspense>
    ),
  },
  {
    path: `settings/organization-structure`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OrganizationStructure} />
      </Suspense>
    ),
  },
  {
    path: `settings/territory-hierarchy`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Hierarchy} />
      </Suspense>
    ),
  },
  {
    path: `settings/Setup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SetupList} />
      </Suspense>
    ),
  },
  {
    path: `settings/LeadScoringSetup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadscoringSetup} />
      </Suspense>
    ),
  },
  {
    path: `geo-location`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={GeoLocation} />
      </Suspense>
    ),
  },
  {
    path: `calendar`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={GoogleCalendar} />
      </Suspense>
    ),
  },
  {
    path: `calendar-view`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CalendarView} />
      </Suspense>
    ),
  },
  {
    path: `gmail`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={GMAILAPI} />
      </Suspense>
    ),
  },
  {
    path: `linkedIn`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LinkedInAPI} />
      </Suspense>
    ),
  },
  {
    path: `facebook`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={FacebookAPI} />
      </Suspense>
    ),
  },
  {
    path: `Work-Flow`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ListWorkflowRule} />
      </Suspense>
    ),
  },
  {
    path: `Lead-Questions`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadQuestions} />
      </Suspense>
    ),
  },
  {
    path: `SocialCampaign`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SocialMediaCampaign} />
      </Suspense>
    ),
  },
  {
    path: `Lead-QualifyQuestions`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <Page component={LeadQuestionsPage} layout={"solo-page"} />
      </Suspense>
    ),
  },
  {
    path: `task/Hierarchy`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={TaskHierarchy} />
      </Suspense>
    ),
  },
  {
    path: `sales-sales-Notifications`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={NotificationSales} />
      </Suspense>
    ),
  },
  {
    path: `Home-Notifications`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={NotificationHome} />
      </Suspense>
    ),
  },
  {
    path: `Add-Formbuilder`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={FormBuilder} />
      </Suspense>
    ),
  },
  {
    path: `Add-FormbuilderList`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustormFormList} />
      </Suspense>
    ),
  },
  {
    path: `CFList/:id`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CFList} />
      </Suspense>
    ),
  },
  {
    path: `SalesCallActivity`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesPersonTarget} />
      </Suspense>
    ),
  },
  {
    path: `Instagram`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={InstagramAPI} />
      </Suspense>
    ),
  },
  {
    path: `AddAgent`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={AddAgent} />
      </Suspense>
    ),
  },
  {
    path: `ChatandSupport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ChatandSupport} />
      </Suspense>
    ),
  },


  //--------------------------------- Report Routes Start --------------------------------------------
  {
    path: `reports/SalesPipeLineReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesPipeLineReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/SalesAchievements`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesAchievements} />
      </Suspense>
    ),
  },
  {
    path: `reports/SalesRepPerformanceReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesRepPerformanceReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/ProAndSerPerformanceReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ProAndSerPerformanceReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/SalesForecastReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesForecastReports} />
      </Suspense>
    ),
  },
  {
    path: `reports/SalesActivityReports`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesActivityReports} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/LeadConversionreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadConversionreport} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/Opportunitypipelinereport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Opportunitypipelinereport} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/Salesforecast`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesForecast} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/DealSize`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DealSize} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/SalesFunnelReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesFunnelReport} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/LeadSourceReports`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadSourceReports} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/SalesPipelineReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesPipelineReport} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/OpportunityAgingReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OpportunityAgingReport} />
      </Suspense>
    ),
  },
  {
    path: `leads/addleads/ConversionRateReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ConversionRateReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerInteractionHistoryReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerInteractionHistoryReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/EmailCampaignPerformanceReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={EmailCampaignPerformanceReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/SocialMediaEngagementReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SocialMediaEngagementReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerSatisfactionReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerSatisfactionReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/WebsiteTrafficReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={WebsiteTrafficReport} />
      </Suspense>
    ),
  },
  {
    path: `leads/LeadGenerationReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadGenerationReport} />
      </Suspense>
    ),
  },
  {
    path: `leads/ConversionMarketingRateReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ConversionMarketingRateReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerChurnReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerChurnReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerLoyaltyReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerLoyaltyReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerRetentionSatisfactionReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerRetentionSatisfactionReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerEngagementReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerEngagementReport} />
      </Suspense>
    ),
  },
  {
    path: `customer-support/CustomerLifetimeValueReport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerLifetimeValueReport} />
      </Suspense>
    ),
  },
  {
    path: `Outlook-Mail`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={OutlookInbox} />
      </Suspense>
    ),
  },
  {
    path: `customerengagementlist`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CustomerEngagementList} />
      </Suspense>
    ),
  },
  {
    path: `leads/unAsignedLeads`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={UnAsignedLeads} />
      </Suspense>
    ),
  },
  {
    path: `FeedBack-QualifyQuestions`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <Page component={FeedbackQstpage} layout={"solo-page"} />
      </Suspense>
    ),
  },
  {
    path: `/MLAnalytics`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <Page component={MainPage} layout={"solo-page"} />
      </Suspense>
    ),
  },
  {
    path: `sale-sales-feedback`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Feedback} />
      </Suspense>
    ),
  },
  {
    path: `reports/Weeklysalesreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={WeeklyReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/Monthlysalesreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={MonthlySalesReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/CustomerChurnreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CusChurnReports} />
      </Suspense>
    ),
  },
  {
    path: `reports/Customeradditionreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CusAdditionReports} />
      </Suspense>
    ),
  },
  {
    path: `reports/Customerretentionreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={CusRetentionReports} />
      </Suspense>
    ),
  },
  {
    path: `reports/newreports/Salesrepperformreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesrepPErformanceReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/LeadandOpportunityreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={LeadandOpp} />
      </Suspense>
    ),
  },
  {
    path: `reports/Salesperformancereport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesperReports} />
      </Suspense>
    ),
  },
  {
    path: `reports/salesFunnelreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={SalesFunReport} />
      </Suspense>
    ),
  },
  {
    path: `reports/PipelineDevelopmentreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={PipelineDevReports} />
      </Suspense>
    ),
  },
  {
    path: `reports/StageDistributionCRMreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={StagedisCRMreports} />
      </Suspense>
    ),
  },
  {
    path: `sales-productCategory`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Product_Catagory} />
      </Suspense>
    ),
  },
  {
    path: `sales-productGroup`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Product_Group} />
      </Suspense>
    ),
  },
  {
    path: `sales-productItem`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={Product_Item} />
      </Suspense>
    ),
  },
  {
    path: `sales-shipmentcalender`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={ShipmentCalender} />
      </Suspense>
    ),
  },
  {
    path: `reports/Dailysalesreport`,
    element: (
      <Suspense fallback={<CRMCustomLoader />}>
        <ProtectedRoute component={DailySalesReport} />
      </Suspense>
    ),
  },

];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  // {
  //     path: "/login",
  //     element: <ProtectedRoute component={Login} layout={"solo-page"} disableSmLogin={false}/>
  // },
  // {
  //     path: "/",
  //     element: <Page component={LandingPage} layout={"solo-page"} disableSmLogin={true} />
  // },


  {
    path: '*',
    element: (
      <Page component={NotFoundPage} layout={"solo-page"} disableSmLogin={true} />
    )
  },

  {
    path: `/`,
    element: <Navigate to={`/${Tenantname}`} replace={true} />,
  },
  {
    path: `/${Tenantname}`,
    element: (
      <Page component={login} layout={"solo-page"} disableSmLogin={true} />
    ),
  },

  {
    path: "/auth-pages/forgot-password",
    element: (
      <Page
        component={ForgotPassword}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: `auth-pages/ResetPassWord`,
    element: (
      <Page
        component={ResetPassword}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
];


const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

// const token = localStorage.getItem('accesstoken');
// let routes = [...authRoutes];

// if (Boolean(token)) {
//   routes = [...routes, ...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly];
// } else {
//   routes = [...routes, ...routesForNotAuthenticatedOnly];
// }


export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
